import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { DEFAULT_LANG } from "../../constants";
import * as types from "../../stores/types";
import { init, updateFilters } from "../../stores/filter/reducers";
import debounce from "../../functions/debounce";
// import { preferredLanguage } from "../../functions/urlLanguage";
import memoizedState from "../../functions/react/memoizedState";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import { addIfNotIncluded } from "../../functions/array";
import GeneralFilter from "./GeneralFilter";
import PriceFilter from "./PriceFilter";
import CategoriesFilter from "./CategoriesFilter";
import DateFilter from "./DateFilter";
import "./ProductsFilter.css";
import ActivitiesSort from "./ActivitiesSortFilter";

let isInitialStateChange = true;
const callOnStateChange = memoizedState(init);
const callOnCategoriesChange = memoizedState(init.categoryId);

function ProductsFilter({
  isMobile,
  isDesktop,
  products,
  categories,
  dispatch,
  city: { id: cityId, slug: citySlug },
  lang = DEFAULT_LANG,
  i18n,
  filtersState,
  prefetched,
}) {
  const [appliedCategories, setAppliedCategories] = useState(filtersState.categoryId);
  const [sortOrder, setSortOrder] = useState("recommended");
  const [sortOrderText, setSortOrderText] = useState(i18n._(t`Recommended`));
  const { categoryId: queryCategory, subcategoryId } = useParams();
  const sortOrderState = useSelector(state => state.filters.order);
  const previewLang = useCurrentLanguage();

  // The logic of the presented filter by browser language. Remove if not required
  // const getPreferredLanguageCode = preferredLanguage => {
  //   return preferredLanguage ? preferredLanguage.slice(0, 2) : null;
  // };
  // const preferredLanguageCode = getPreferredLanguageCode(preferredLanguage);

  const defaultLanguage = isInitialStateChange ? previewLang : "";

  const fetchProducts = useCallback(
    ({ filters = {}, ...props }) => {
      const currentSortOrder = sortOrderState || sortOrder;
      dispatch({
        type: types.FETCH_PRODUCTS,
        cityId,
        subcategoryId,
        order: currentSortOrder,
        ...filters,
        lang: filters.lang?.length ? filters.lang : defaultLanguage,
        ...props,
      });
    },
    [cityId, dispatch, subcategoryId, defaultLanguage, sortOrderState, sortOrder],
  );

  const fetchProductsDebounced = useMemo(() => debounce(fetchProducts, 500), [fetchProducts]);

  /*
    Update `Filters` initially and trigger the first product fetch.
    Relocated here to prevent a double fetch. (both here and inside `getInitialProps` of the parent page).
    They can be moved back when the `City` and `Collection` pages share a common root.
  */
  useEffect(() => {
    if (isInitialStateChange) {
      let { categoryId } = filtersState.categoryId;

      if (queryCategory) {
        categoryId = addIfNotIncluded(filtersState.categoryId, queryCategory);
        updateFilters(dispatch, { categoryId });
        setAppliedCategories(categoryId);
      }
      dispatch({
        type: types.SET_PRODUCTS_FILTERS,
        payload: { lang: [defaultLanguage] },
      });
      // Will fetch products and store inside both: `prefetched` and `products`
      fetchProducts({ filtersState, separateTo: "prefetched", storeBoth: true, categoryId });
    }
  }, [defaultLanguage]);

  useEffect(() => {
    callOnStateChange(filtersState, () => {
      // prevent double fetch on initiall updateFilters
      if (isInitialStateChange) {
        isInitialStateChange = false;
        if (queryCategory) return;
      }
      fetchProductsDebounced({ filters: filtersState, order: sortOrderState });
    });
  }, [fetchProductsDebounced, filtersState, queryCategory, defaultLanguage, sortOrderState]);

  const applyFilters = (newCategories = filtersState.categoryId) => {
    // TODO: apply no matter of prefetched.products
    if (prefetched.products?.length) {
      dispatch({ type: types.UPDATE_BY_PREFETCHED });
      callOnCategoriesChange(newCategories, () => {
        setAppliedCategories(newCategories);
      });
    }
  };

  const handleSortChange = (value, text) => {
    setSortOrder(value);
    setSortOrderText(text);
    dispatch({ type: types.SET_SORT_ORDER, payload: { order: value } });
    fetchProductsDebounced({ filters: filtersState, order: value });
  };

  const sharedProps = { i18n, lang, isMobile, dispatch, onApply: applyFilters };

  return (
    <div className="ProductsFilter">
      <div className="ProductsFilter__container">
        <div className="ProductsFilter__container_popups">
          <GeneralFilter products={products} categories={categories} {...sharedProps} />
          <PriceFilter currency={products[0]?.currency} categories={categories} {...sharedProps} />
          <DateFilter {...sharedProps} />
        </div>
        {categories && (
          <CategoriesFilter
            cityId={cityId}
            citySlug={citySlug}
            categories={categories}
            isDesktop={isDesktop}
            appliedCategories={appliedCategories}
            {...sharedProps}
          />
        )}
      </div>
      <ActivitiesSort
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        sortOrderText={sortOrderText}
        setSortOrderText={setSortOrderText}
        handleSortChange={handleSortChange}
        prefetchedCount={prefetched.count}
        {...sharedProps}
      />
    </div>
  );
}

const mapStateToProps = ({ filters, products }) => ({
  prefetched: products.prefetched,
  products: products.products || [],
  filtersState: filters,
});

export default withI18n()(connect(mapStateToProps)(ProductsFilter));
