/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import "./MostRecommended.css";
import { Trans } from "@lingui/macro";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import { getProductLink } from "../../functions/getProductLink";

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

const MostRecommended = ({ categoryTitle, city, lang, mostRecommended = [] }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const cityName = city?.name ? inclineIfNeeded(city.name, "in", lang) : "";

  const toggleExpand = index => {
    setExpandedIndex(prevState => (prevState === index ? null : index));
  };

  return (
    <>
      <div className="MostRecommended__head-title">
        <Trans>
          Most recommended in {cityName} {categoryTitle || null}
        </Trans>
      </div>
      <div className="MostRecommended">
        {mostRecommended.slice(0, 10).map((item, index) => {
          const isExpanded = expandedIndex === index;
          const titleDisplay = isExpanded ? item.title : truncateText(item.title, 45);
          const descriptionDisplay = item.description;
          const productLink = getProductLink(lang, item);

          return (
            <div
              key={index}
              className={`MostRecommended__card ${isExpanded ? "MostRecommended__expanded" : ""}`}
            >
              <img src={item.cover} alt={item.title} className="MostRecommended__card_image" />
              <div className="MostRecommended__card_content">
                <a className="MostRecommended__card_title" href={productLink} target="_blank">
                  {titleDisplay}
                </a>
                <p
                  className={`MostRecommended__card_description ${
                    !isExpanded ? "MostRecommended__card_description--collapsed" : ""
                  }`}
                >
                  {descriptionDisplay}
                </p>
                {item.description.length > 128 && (
                  <button
                    className="MostRecommended__toggle-button"
                    onClick={() => toggleExpand(index)}
                  >
                    {isExpanded ? <Trans>Show less</Trans> : <Trans>Show more</Trans>}
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MostRecommended;
